.input-wrapper {
    box-sizing: border-box;
    width: 100%;
    height: 44px;
    border: 1px solid #D9D9D9;
    border-radius: 30px;
    padding: 2px;
    font-size: 0;
}

.input-wrapper input {
    padding: 10px;
    border: none;
    height: 44px;
    background: transparent;
    width: calc(100% - 67px);
}

.input-wrapper input::placeholder {
    color:rgba(153, 153, 153, 0.3);

}

.input-wrapper button {
    width: 67px;
    height: 38px;
    background: #19A0DD;
    border-radius: 30px;
    border: none;
    color: #fff;
    cursor: pointer;
    vertical-align: top;
}

.input-wrapper input:focus {
    border: none;
    outline: none;
}