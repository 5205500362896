.modal-backdrop {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    height: 100vh;
    width: 100vw;
    left: 0;
    top: 0;
    z-index: 1000;
}

.modal-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1001;
}

.modal-content,
.modal-wrapper {
    left: 0;
    position: fixed;
    right: 0;
}




/* .modal-content {
    background-color: #fff;
    position: fixed;
    border-radius: 25px;
    color: #000;
    height: auto;
    margin: auto;
    padding: 30px;
    top: 50%;
    transform: translateY(-50%);
    width: 40vw;
    text-align: center;
} */

.modal-content {
    background-color: #fff;
    position: fixed;
    border-radius: 25px;
    color: #000;
    height: auto;
    margin: auto;
    padding: 30px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 45vw;
    text-align: center;
} 

.modal-header {
    background-color: #19A0DD;
    border-radius: 15px 15px 0 0;
    padding: 25px;
    text-align: center;
    color: #fff;
    margin-bottom: 10px;
}